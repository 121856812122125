import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';
import { delay, filter, tap } from 'rxjs/operators';
import { Toast } from 'src/app/shared/models/Toast.model';
import { ToastService } from 'src/app/shared/services/toast.service';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('detailExpand', [
      state('inactive', style({ bottom: '-90px' })),
      state('active', style({ bottom: '40px' })),
      transition(
        'inactive <=> active',
        animate('400ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class ToastComponent implements OnInit, OnDestroy {
  toastActive$: boolean;
  payload$: Toast;

  toastDisplaySubscription: Subscription;

  constructor(private toastService: ToastService) {}

  ngOnInit(): void {
    this.toastDisplaySubscription = this.toastService.toasted$
      .pipe(
        filter(() => !this.toastActive$),
        tap((payload) => {
          this.payload$ = payload;
        }),
        tap(() => {
          this.toastActive$ = true;
        }),
        delay(3000),
        tap(() => {
          this.toastActive$ = false;
        }),
        delay(500),
        tap(() => {
          this.payload$ = null;
        })
      )
      .subscribe();
  }

  get type() {
    return this.payload$?.type || '';
  }

  ngOnDestroy(): void {
    this.toastDisplaySubscription?.unsubscribe();
  }
}
