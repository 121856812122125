import { BaseCommand } from './BaseCommand';

export class ConfigCommand implements BaseCommand {
  refreshRateMillis: number; // Rate in milliseconds the remote access agent app will upload fresh screenshots and apply new commands
  qualityPercent: number; // Quality of the image in percent
  imageType: string; // Image type (PNG or JPG)

  constructor(
    refreshRateMillis: number,
    qualityPercent: number,
    imageType: string,
  ) {
    this.refreshRateMillis = refreshRateMillis;
    this.qualityPercent = qualityPercent;
    this.imageType = imageType;
  }

  /**
   * The Remote Access Agent expects this command to be in the format:
   * config,<uploadRateMillis>,<qualityPercent>,<imageType>,<commandPollRateMillis>
   *
   * Any changes to the "refreshRate" in the UI will be applied to BOTH the "uploadRate" and "commandPollRate"
   *
   * @returns string
   */
  toCommandString = () =>
    `config,${this.refreshRateMillis},${this.qualityPercent},${this.imageType},${this.refreshRateMillis}`;
}
