import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { OverlayLoaderComponent } from './components/overlay-loader/overlay-loader.component';
import { ToastComponent } from './components/toast/toast.component';

@NgModule({
  declarations: [ToastComponent, OverlayLoaderComponent],
  imports: [CommonModule],
  exports: [ToastComponent, OverlayLoaderComponent],
})
export class SharedModule {}
