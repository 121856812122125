import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseApiResponse } from '../models/BaseApiResponse.model';
import { Ccu } from '../models/Ccu.model';
import { RemoteAccessConfig } from '../models/RemoteAccessConfig.model';
import { ApiService } from '../services/api.service';
import { ConfigurationService } from '../services/configuration.service';

@Injectable({
  providedIn: 'root',
})
export class RemoteAccessClient {
  private remoteAccessServiceUrl: string;

  constructor(
    private apiService: ApiService,
    private configService: ConfigurationService
  ) {
    this.remoteAccessServiceUrl = this.configService.get(
      'remoteAccessServiceUrl'
    );
  }

  getRemoteAccessConfig(ccuId: string): Observable<RemoteAccessConfig> {
    return this.apiService
      .get<BaseApiResponse<RemoteAccessConfig>>(
        `${this.remoteAccessServiceUrl}/ccu/${ccuId}/config`
      )
      .pipe(map((response) => response.data));
  }

  getDiscoveredCcusForSite(siteId: string): Observable<Ccu[]> {
    return this.apiService
      .get<BaseApiResponse<Ccu[]>>(
        `${this.remoteAccessServiceUrl}/ccu?siteId=${siteId}`
      )
      .pipe(map((response) => response.data));
  }

  getDiscoveredCcuById(siteId: string, ccuId: string): Observable<Ccu> {
    return this.apiService
      .get<BaseApiResponse<Ccu[]>>(
        `${this.remoteAccessServiceUrl}/ccu?siteId=${siteId}&ccuId=${ccuId}`
      )
      .pipe(
        map((response) =>
          response.data?.length ? response.data[0] : undefined
        )
      );
  }

  postCommand(
    sessionId: string,
    ccuId: string,
    command: string,
    timestamp: number
  ): Observable<void> {
    const url = `${this.remoteAccessServiceUrl}/session/${sessionId}/ccu/${ccuId}/command`;
    return this.apiService.post<void>(url, {
      value: command,
      timestampMillis: timestamp,
    });
  }

  startRemoteAccessSession(siteId: string, ccuId: string): Observable<string> {
    const url = `${this.remoteAccessServiceUrl}/session/start`;

    return this.apiService
      .post<any>(url, { siteId, ccuId })
      .pipe(map((response) => response.data?.sessionId));
  }

  endRemoteAccessSession(sessionId: string): Observable<any> {
    const url = `${this.remoteAccessServiceUrl}/session/${sessionId}/stop`;
    return this.apiService.post(url, null);
  }

  endRemoteAccessSessionByCcuId(ccuId: string): Observable<any> {
    const url = `${this.remoteAccessServiceUrl}/session/ccu/${ccuId}/stop`;
    return this.apiService.post(url, null);
  }

  getScreenshot(sessionId: string): Observable<any> {
    const url = `${this.remoteAccessServiceUrl}/session/${sessionId}/screenshot`;
    return this.apiService
      .get<BaseApiResponse<any>>(url)
      .pipe(map((response) => response?.data));
  }
}
