import { BaseCommand } from './BaseCommand';

// The swipe command is emitted in the event of a click-and-drag or a scrollwheel movement.
// It's been observed that a single step of a click wheel can result in a zero-duration event.
// In that case, we need to prevent these from being unintentionally converted to fling movements 
// on the tablet by assigning an arbitrary default when a zero-duration scroll event comes in
const DEFAULT_SWIPE_DURATION_MS = 500

export class SwipeCommand implements BaseCommand {
  x1: number;
  y1: number;
  x2: number;
  y2: number;
  duration: number; // Milliseconds

  constructor(
    x1: number,
    y1: number,
    x2: number,
    y2: number,
    duration: number
  ) {
    this.x1 = Math.round(x1);
    this.y1 = Math.round(y1);
    this.x2 = Math.round(x2);
    this.y2 = Math.round(y2);
    this.duration = Math.round(duration) || DEFAULT_SWIPE_DURATION_MS;
  }

  toCommandString = () =>
    `swipe,${this.x1},${this.y1},${this.x2},${this.y2},${this.duration}`;
}
