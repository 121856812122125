import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SiloCcu } from '../models/SiloCcu.model';
import { ApiService } from '../services/api.service';
import { ConfigurationService } from '../services/configuration.service';

@Injectable({
  providedIn: 'root',
})
export class SiloClient {
  private siloUrl: string;

  constructor(
    private apiService: ApiService,
    private configService: ConfigurationService
  ) {
    this.siloUrl = this.configService.get('siloUrl');
  }

  getCcusBySite(siteId: string): Observable<SiloCcu[]> {
    const url = `${this.siloUrl}/read?filter=siteRef == @${siteId} and ccu`;
    return this.apiService
      .get<any>(url)
      .pipe(
        map((responseGrid) =>
          responseGrid.rows.map((ccu) => this.formatCcuEntity(ccu))
        )
      );
  }

  getCcuById(ccuId: string): Observable<SiloCcu> {
    const url = `${this.siloUrl}/read?ids=@${ccuId}`;
    return this.apiService
      .get<any>(url)
      .pipe(map((responseGrid) => this.formatCcuEntity(responseGrid.rows[0])));
  }

  private formatCcuEntity(entity: any) {
    return {
      ccuId: entity.id.slice(2),
      name: entity.dis,
    };
  }
}
