import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { User } from '../models/User.model';
import { ApiService } from '../services/api.service';
import { ConfigurationService } from '../services/configuration.service';

@Injectable({
  providedIn: 'root',
})
export class CaretakerClient {
  private caretakerUrl: string;

  constructor(
    private apiService: ApiService,
    private configService: ConfigurationService
  ) {
    this.caretakerUrl = this.configService.get('caretakerUrl');
  }

  /**
   * Get the logged in user object
   * @returns Logged in user info
   */
  getUserInfo(): Observable<User> {
    const url = `${this.caretakerUrl}/token/userinfo`;
    return this.apiService.get<User>(url);
  }
}
