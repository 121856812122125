import { BaseCommand } from './BaseCommand';

export class KeysCommand implements BaseCommand {
  keys: string[];

  constructor(keys: string[]) {
    this.keys = keys;
  }

  toCommandString = () => `keys,${this.keys.join('')}`;
}
