import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { BaseApiResponse } from '../models/BaseApiResponse.model';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  bearerToken: string;
  sourceApp: string;

  constructor(private http: HttpClient) {}

  get jsonTypeHeaders() {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      '75F-Application': this.sourceApp,
      Authorization: `Bearer ${this.bearerToken}`,
    });
  }

  get<T>(url: string): Observable<T> {
    return this.http
      .get<BaseApiResponse<T>>(url, {
        headers: this.jsonTypeHeaders,
        observe: 'body',
      })
      .pipe(catchError((err) => this.handleHttpError(err)));
  }

  post<T>(url: string, payload: any): Observable<T> {
    return this.http
      .post(url, payload, { headers: this.jsonTypeHeaders, observe: 'body' })
      .pipe(catchError((err) => this.handleHttpError(err)));
  }

  private handleHttpError(err: HttpErrorResponse): Observable<any> {
    console.error({
      message: `There was an error processing your request. Please try again. (error code: ${err.status})`,
    });

    return throwError(() => err);
  }
}
