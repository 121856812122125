import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { exhaustMap, interval, Observable, of, Subscription, tap } from 'rxjs';
import { RemoteAccessClient } from 'src/app/shared/clients/remote-access.client';
import { SiloClient } from 'src/app/shared/clients/silo.client';
import { Ccu } from 'src/app/shared/models/Ccu.model';
import { SiloCcu } from 'src/app/shared/models/SiloCcu.model';
import { User } from 'src/app/shared/models/User.model';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { ConfigurationService } from 'src/app/shared/services/configuration.service';
import { UtilService } from 'src/app/shared/services/util.service';

@Component({
  selector: 'ccu-select',
  templateUrl: './ccu-select.component.html',
  styleUrls: ['./ccu-select.component.scss'],
})
export class CcuSelectComponent implements OnInit, OnDestroy {
  @Input()
  siteId: string;

  @Output()
  selectionChange: EventEmitter<Ccu> = new EventEmitter();

  availableCcus: Ccu[] = [];

  rasDiscoveredCcus: Ccu[];
  siloCcus: SiloCcu[];

  selectedCcu: Ccu;

  currentUser: User;

  ccuStatusPollingSubscription: Subscription;

  constructor(
    private authenticationService: AuthenticationService,
    private configurationService: ConfigurationService,
    private remoteAccessClient: RemoteAccessClient,
    private siloClient: SiloClient,
    private utilService: UtilService
  ) {}

  ngOnInit(): void {
    this.currentUser = this.authenticationService.currentUser;

    this.loadAvailableCcus();
  }

  loadAvailableCcus(): void {
    if (!this.siteId) {
      this.availableCcus = [];
      return;
    }

    // Request CCUs from this site from both Silo and RAS in parallel
    // The last one to finish merges the results into the list of distinct CCUs
    // which populates the dropdown list
    this.siloClient.getCcusBySite(this.siteId).subscribe((siloCcus: any[]) => {
      this.siloCcus = siloCcus;
      this.mergeSiloAndRasDiscoveredCcus();
    });

    this.remoteAccessClient
      .getDiscoveredCcusForSite(this.siteId)
      .subscribe((ccus: Ccu[]) => {
        this.rasDiscoveredCcus = ccus;
        this.mergeSiloAndRasDiscoveredCcus();
        this.startCcuStatusPolling();
      });
  }

  /**
   * Kicks off a job which polls on a fixed interval to refresh the status of each discovered CCU on the selected site.
   * This is initiated once the discovered CCUs are received from the Remote Access Service.
   */
  startCcuStatusPolling(): Subscription {
    if (this.ccuStatusPollingSubscription) {
      this.ccuStatusPollingSubscription.unsubscribe();
    }

    return interval(this.configurationService.ccuStatusPollFrequencyMs)
      .pipe(exhaustMap(() => this.refreshCcuStatuses()))
      .subscribe();
  }

  refreshCcuStatuses(): Observable<any> {
    if (!this.availableCcus?.length) {
      return of(null);
    }

    return this.remoteAccessClient
      .getDiscoveredCcusForSite(this.siteId).pipe(
        tap((updatedCcus) => {
          const ccuLookup = this.availableCcus
            .reduce((lookup, ccu) => { lookup[ccu.ccuId] = ccu; return lookup }, {});

          updatedCcus.forEach((updatedCcu) => {
            const existingCcu = ccuLookup[updatedCcu.ccuId];
            if (existingCcu.status !== updatedCcu.status) {
              existingCcu.status = updatedCcu.status;
            }
          });
        })
      );
  }

  canStartSession(ccu: Ccu): boolean {
    return this.authenticationService.canStartSession(ccu)
  }

  handleCcuSelectionChange(ccuSelection: MatSelectChange): void {
    this.selectionChange.emit(ccuSelection.value);
  }

  /**
   * Once the CCU records have been returned from both RAS and Silo, we may need to
   * fill in missing CCUs that have not been discovered by RAS with the entities from Silo.
   *
   * This combines both responses into the availableCcus list
   */
  mergeSiloAndRasDiscoveredCcus(): void {
    if (!this.siloCcus || !this.rasDiscoveredCcus) {
      return;
    }

    const ccuLookup: { [key: string]: Ccu } = this.rasDiscoveredCcus.reduce(
      (runningLookup, ccu) => ({
        ...runningLookup,
        [ccu.ccuId]: ccu,
      }),
      {}
    );

    // Merge the Silo and discovered CCUs, then sort by Name
    this.availableCcus = this.siloCcus
      .map((siloCcu) =>
        this.utilService.mergeSiloAndRasDiscoveredCcu(
          siloCcu,
          ccuLookup[siloCcu.ccuId],
          this.siteId
        )
      )
      .sort((a, b) =>
        a.ccuMetadata.name
          .toLocaleUpperCase()
          .localeCompare(b.ccuMetadata.name.toUpperCase())
      );
  }

  ngOnDestroy(): void {
    this.ccuStatusPollingSubscription?.unsubscribe();
  }
}
