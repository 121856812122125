import { HammerGestureConfig } from '@angular/platform-browser';
import * as Hammer from 'hammerjs';

export class HammerJsConfiguration extends HammerGestureConfig {
  overrides = <any>{
    tap: { time: 20000 }, // Maximum duration (ms) of tap events that will be captured
    swipe: {
      velocity: 0.01, // Minimum cursor velocity (px/ms) required to register a swipe event
      direction: Hammer.DIRECTION_ALL, // Allows both horizontal & vertical swipe actions to be captured. The default is supposed to be 'DIRECTION_ALL`. Turned out to be a lie.
    },
    pinch: { enable: false },
    rotate: { enable: false },
  };
}
