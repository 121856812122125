import {
  Component,
  Input,
  OnDestroy,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatSliderChange } from '@angular/material/slider';
import { Subscription, catchError, throwError } from 'rxjs';
import { RemoteAccessClient } from 'src/app/shared/clients/remote-access.client';
import { ConfigCommand } from 'src/app/shared/models/commands/ConfigCommand';
import { ConfigurationService } from 'src/app/shared/services/configuration.service';
import { ToastService } from 'src/app/shared/services/toast.service';

@Component({
  selector: 'settings-menu-button',
  templateUrl: './settings-menu-button.component.html',
  styleUrls: ['./settings-menu-button.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SettingsMenuButtonComponent implements OnDestroy {
  @Input()
  ccuId: string;

  @Input()
  sessionId: string;

  @ViewChild('settingsMenuTrigger') settingsMenuTrigger: MatMenuTrigger;

  menuOpenSubscription: Subscription;

  qualityPercentage: number = 50;
  refreshIntervalMillis: number = 1000;

  get isChanged(): boolean {
    return this.qualityPercentage !== this.configurationService.remoteAccessConfig.quality || 
      this.refreshIntervalMillis !== this.configurationService.remoteAccessConfig.refreshFrequency;
  }

  constructor(
    private configurationService: ConfigurationService,
    private remoteAccessClient: RemoteAccessClient,
    private toastService: ToastService,
  ) {}

  ngAfterViewInit(): void {
    this.menuOpenSubscription = this.subscribeToMenuOpen();
  }

  /**
   * Subscribes to the menuOpened event of the settings menu trigger and
   * refreshes the settings to the currently applied values when the menu is opened.
   *
   * @returns Subscription
   */
  private subscribeToMenuOpen(): Subscription {
    return this.settingsMenuTrigger.menuOpened.subscribe(() => {
      this.applyConfigDefaults();
    });
  }

  private applyConfigDefaults(): void {
    this.qualityPercentage =
      this.configurationService.remoteAccessConfig.quality;

    this.refreshIntervalMillis =
      this.configurationService.remoteAccessConfig.refreshFrequency;
  }

  handleQualityChange(change: MatSliderChange): void {
    this.qualityPercentage = change.value;
  }

  handleRefreshIntervalChange(change: MatSliderChange): void {
    this.refreshIntervalMillis = change.value * 1000;
  }

  handleCancel(): void {
    this.settingsMenuTrigger.closeMenu();
  }

  handleApply(): void {
    const configCommand = new ConfigCommand(
      this.refreshIntervalMillis,
      this.qualityPercentage,
      this.configurationService.remoteAccessConfig.imageType,
    );

    // 'isChanged' also controls whether the apply button is enabled or not.
    // So this really shouldn't happen, but just in case.
    if (!this.isChanged) {
        this.toastService.success('Nothing Changed');
        return;
    }

    this.remoteAccessClient
      .postCommand(
        this.sessionId,
        this.ccuId,
        configCommand.toCommandString(),
        new Date().getTime(),
      )
      .pipe(
        catchError((error) => {
          this.toastService.error('Failed to apply settings change');
          return throwError(() => error);
        })
      )
      .subscribe(() => {
        // Results in an event being broadcast to the `configChangeSubject` in the ConfigurationService.
        // This is then picked up by the Screenshot component which then refreshes its screenshot poll loop with the new settings.
        this.configurationService.updateSessionSettings({
          qualityPercentage: this.qualityPercentage,
          refreshIntervalMillis: this.refreshIntervalMillis,
        });

        this.toastService.success('Settings Applied');

        this.settingsMenuTrigger.closeMenu();
      });
  }

  ngOnDestroy(): void {
    this.menuOpenSubscription?.unsubscribe();
  }
}
