import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { User } from 'src/app/shared/models/User.model';
import { CaretakerClient } from '../clients/caretaker.client';
import { DeviceStatus } from '../enums/DeviceStatus.enum';
import { Ccu } from '../models/Ccu.model';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  currentUser: User;

  constructor(private caretakerClient: CaretakerClient) {}

  getAndInitializeUserInfo(): Observable<User> {
    return this.caretakerClient.getUserInfo().pipe(
      tap((user) => {
        this.currentUser = user;
      })
    );
  }

  /**
   * An in-progress remote access session can be overriden by any support user or the "owner" of the session.
   * Otherwise the ccu must be in the AVAILABLE state to start a session 
   */
  canStartSession(ccu: Ccu): boolean {
    if (ccu.status === DeviceStatus.AVAILABLE) {
      return true;
    }

    return ccu.status === DeviceStatus.IN_PROGRESS && 
      (!!this.currentUser?.isSupport || ccu.session.emailId === this.currentUser?.emailId);
  }
}
