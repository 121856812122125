import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ToastType } from '../enums/ToastType.enum';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  private toastedSource: Subject<any> = new Subject<any>();
  toasted$ = this.toastedSource.asObservable();

  constructor() {}

  success(message: string) {
    this.toastedSource.next({ type: ToastType.SUCCESS, message });
  }

  error(message: string) {
    this.toastedSource.next({ type: ToastType.ERROR, message });
  }
}
