import { BaseCommand } from './BaseCommand';

export class TapCommand implements BaseCommand {
  x: number;
  y: number;
  duration: number; // Milliseconds

  constructor(x: number, y: number, duration: number) {
    this.x = x;
    this.y = y;
    this.duration = duration;
  }

  toCommandString = () => `tap,${this.x},${this.y},${this.duration}`;
}
