import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import {
  Observable,
  Subject,
  Subscription,
  buffer,
  bufferTime,
  catchError,
  debounceTime,
  exhaustMap,
  filter,
  forkJoin,
  interval,
  tap,
  throwError,
} from 'rxjs';
import { RemoteAccessClient } from 'src/app/shared/clients/remote-access.client';
import { AppContext } from 'src/app/shared/enums/AppContext.enum';
import { Ccu } from 'src/app/shared/models/Ccu.model';
import { SessionEndEvent } from 'src/app/shared/models/SessionEndEvent.model';
import { BaseCommand } from 'src/app/shared/models/commands/BaseCommand';
import { KeysCommand } from 'src/app/shared/models/commands/KeysCommand.class';
import { NavCommand } from 'src/app/shared/models/commands/NavCommand.class';
import { SpecialKeyCommand } from 'src/app/shared/models/commands/SpecialKeyCommand.class';
import { SwipeCommand } from 'src/app/shared/models/commands/SwipeCommand.class';
import { TapCommand } from 'src/app/shared/models/commands/TapCommand.class';
import { ConfigurationService } from 'src/app/shared/services/configuration.service';
import { ToastService } from 'src/app/shared/services/toast.service';

// Determines the interval at which key events are grouped and sent to the API in the form of key commands
const KEY_DOWN_BUFFER_INTERVAL_MS = 200;

const STALE_SESSION_THRESHOLD_SECONDS = 15;

@Component({
  selector: 'screenshot',
  templateUrl: './screenshot.component.html',
  styleUrls: ['./screenshot.component.scss'],
})
export class ScreenshotComponent implements OnInit, OnDestroy {
  @Input() ccu: Ccu;
  @Input() sessionId: string;
  @Input() aspectRatio: number;

  @Output() sessionEnd: EventEmitter<SessionEndEvent> = new EventEmitter();

  // Used in gesture handlers to adjust x,y coordinates based on window size
  @ViewChild('screenshotContainer') screenshotContainer: ElementRef;

  screenshotSource: SafeResourceUrl; // base64 encoded image data
  screenshotImageType: string;

  latestHeartbeatTimestampMs: number;
  latestScreenshotTimestampMs: number;
  screenshotSizeBytes: number;

  isStaleSession: boolean = false;
  latestHeartbeatBrowserTimestampMs: number;

  screenshotPollingSubscription: Subscription;

  mouseWheelCaptureSubject: Subject<WheelEvent> = new Subject();
  mouseWheelCaptureSubscription: Subscription;

  keyPressCaptureSubject: Subject<KeyboardEvent> = new Subject();
  keyPressCaptureSubscription: Subscription;

  configChangeSubscription: Subscription;

  debugModeEnabled: boolean = false;
  sourceApp: string;

  constructor(
    private configurationService: ConfigurationService,
    private dialog: MatDialog,
    private domSanitizer: DomSanitizer,
    private remoteAccessClient: RemoteAccessClient,
    private toastService: ToastService,
  ) {
    this.screenshotImageType = `image/${this.configurationService.remoteAccessConfig.imageType.toLocaleLowerCase()}`;
    this.debugModeEnabled = this.configurationService.debugModeEnabled;
    this.sourceApp = this.configurationService.hostApp;
  }

  ngOnInit(): void {
    if (!this.ccu || !this.sessionId) {
      this.toastService.error(
        'Missing required ccu and/or sessionId parameters'
      );
      return;
    }

    // Initialize long-running subscriptions (these are unsubscribed in the ngOnDestry())
    this.screenshotPollingSubscription = this.startScreenshotPolling();
    this.mouseWheelCaptureSubscription = this.startMouseWheelCapture();
    this.keyPressCaptureSubscription = this.startKeyPressCapture();
    this.configChangeSubscription = this.subscribeToConfigChanges();

    // When 'appContext' and 'targetEntityId' are provided, automatically send a "nav" command to the CCU at the start of the session
    if (this.configurationService.appContext && this.configurationService.targetEntityId) {
      this.sendNavCommand(this.configurationService.appContext, this.configurationService.targetEntityId);
    }
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardDownEvent(event: KeyboardEvent): void {
    this.keyPressCaptureSubject.next(event);
  }

  @HostListener('mousewheel', ['$event'])
  handleMousewheel(event: WheelEvent) {
    this.mouseWheelCaptureSubject.next(event);
    event.preventDefault();
  }

  /**
   * Creates an rxjs subscription that executes the `loadLatestScreenshot()` function
   * on a fixed interval. The interval is set by the current Remote Access Configuration and the
   * `exhaustMap()` operator ensures that the next screenshot is loaded until the last request completes
   *
   * @returns Subscription
   */
  startScreenshotPolling(): Subscription {
    // It's possible for this to be called after the subscription has already been started in the event that
    // the session settings are changed (i.e. quality, refresh frequency, etc.)
    // In this case, we should unsubscribe from the existing subscription and start a new one with the updated settings
    if (this.screenshotPollingSubscription) {
      this.screenshotPollingSubscription.unsubscribe();
    }

    this.loadLatestScreenshot();

    return interval(
      this.configurationService.remoteAccessConfig.refreshFrequency
    )
      .pipe(exhaustMap(() => this.loadLatestScreenshot()))
      .subscribe();
  }

  /**
   * Uses an RXJS buffer to capture multiple keystrokes in bulk within fixed intervals. This allows
   * key commands to be grouped when dealing with a speed-typer
   *
   * @returns Subscription
   */
  startKeyPressCapture(): Subscription {
    return this.keyPressCaptureSubject
      .pipe(
        bufferTime(KEY_DOWN_BUFFER_INTERVAL_MS),
        filter((events) => events?.length > 0)
      )
      .subscribe((keyPressEvent: KeyboardEvent[]) => {
        this.handleAggregatedKeyPressEvents(keyPressEvent);
      });
  }

  /**
   * Creates a subscription to capture debounced and buffered mouse-wheel events within the component
   * MouseWheel events are recorded in very rapid successession to track the fine-grained movements of the wheel
   * We need to capture and bundle these events into a single gesture that can be posted to the service as a "swipe" command
   *
   * The combination of the debounce and buffer rxjs operators allows these event streams to be handled in bulk
   * once each movement completes
   *
   * @returns Subscription
   */
  startMouseWheelCapture(): Subscription {
    return this.mouseWheelCaptureSubject
      .pipe(buffer(this.mouseWheelCaptureSubject.pipe(debounceTime(200))))
      .subscribe((wheelEvents: WheelEvent[]) => {
        this.handleAggregatedMouseWheelEvents(wheelEvents);
      });
  }

  /**
   * Listens for changes to session settings and restarts the screenshot polling subscription with the updated
   * refresh frequency
   *
   * @returns Subscription
   */
  subscribeToConfigChanges(): Subscription {
    return this.configurationService.configChangeSubject.subscribe(() => {
      this.configChangeSubscription = this.startScreenshotPolling();
    });
  }

  /**
   * Invoked by the Screenshot Polling subscription, this requests the latest screenshot for the current open session
   * from the Remote Access Service
   */
  loadLatestScreenshot(): Observable<any> {
    return this.remoteAccessClient.getScreenshot(this.sessionId).pipe(
      tap((screenshot) => {
        this.handleScreenshotResponse(screenshot);
      }),
      catchError((error, caught) => {
        // A 401 error indicates that the session has been closed somewhere downstream
        // Emit a sessionEnd event to clean up and reset the session in parent component
        if (
          error instanceof HttpErrorResponse &&
          error.status == HttpStatusCode.Unauthorized
        ) {
          // Notify the parent component that the session has been terminated
          this.sessionEnd.emit({unexpected: true});
        }

        return caught;
      })
    );
  }

  handleScreenshotResponse(screenshot) {
    if (!screenshot) {
      return;
    }

    if (screenshot.bytes) {
      this.screenshotSource = this.domSanitizer.bypassSecurityTrustResourceUrl(
        `data:${this.screenshotImageType};base64,${screenshot.bytes}`
      );

      this.latestScreenshotTimestampMs = screenshot.timestampMillis;
      this.screenshotSizeBytes = screenshot.bytes.length;

      if (this.isStaleSession) {
        this.isStaleSession = false;
        this.dialog.closeAll();
      }
    }

    // It is not safe to assume that the browser and server clocks are in sync, so the browser time is captured and compared
    // whenever an updated screenshot timestamp is received. This is then used to determine whether the connection has gone stale
    const currentTimeMs = new Date().getTime();
    if (this.latestHeartbeatTimestampMs != screenshot.timestampMillis) {
      this.latestHeartbeatTimestampMs = screenshot.timestampMillis
      this.latestHeartbeatBrowserTimestampMs = currentTimeMs;
    }
    
    if (!this.isStaleSession && (currentTimeMs - this.latestHeartbeatBrowserTimestampMs) / 1000 > STALE_SESSION_THRESHOLD_SECONDS) {
      this.isStaleSession = true;
      this.showStaleSessionWarningDialog();
    }
  }

  /**
   * Invoked by the buffered KeyPress Capture event subscription once an interval of key down events completes.
   * The key events are grouped between 'keys' and 'special' keys and then each resulting command is posted to the API.
   *
   * @param KeyboardEvent[] Array of keyDown events (assumed to be in order of ocurrence)
   * @returns
   */
  handleAggregatedKeyPressEvents(keyPressEvents: KeyboardEvent[]) {
    if (!keyPressEvents?.length || this.isStaleSession) {
      return;
    }

    const combinedKeyCommands: BaseCommand[] = [];
    keyPressEvents.forEach((keyPressEvent) => {
      const specialKey = SpecialKeyCommand.getMappedSpecialKey(
        keyPressEvent.key
      );

      if (specialKey) {
        combinedKeyCommands.push(new SpecialKeyCommand(specialKey));
        return;
      }

      // Ignore any unregistered Special Keys
      if (keyPressEvent.key.length > 1) {
        return;
      }

      const previousCommand =
        combinedKeyCommands.length &&
        combinedKeyCommands[combinedKeyCommands.length - 1];
      if (previousCommand instanceof KeysCommand) {
        previousCommand.keys.push(keyPressEvent.key);
        return;
      }

      combinedKeyCommands.push(new KeysCommand([keyPressEvent.key]));
    });

    const requests = combinedKeyCommands.map((command) => {
      this.remoteAccessClient
        .postCommand(
          this.sessionId,
          this.ccu.ccuId,
          command.toCommandString(),
          new Date().getTime()
        )
        .subscribe();
    });

    forkJoin([requests]).pipe(
      catchError((error) => {
        this.toastService.error('Failed to transmit key gesture(s)');
        return throwError(() => error);
      })
    );
  }

  /**
   * Wired up directly to the HammerJS (tap) event emmitter. Posts a tap command for each click event.
   *
   * @param event
   * @returns
   */
  handleTap(event): void {
    if (this.isStaleSession) {
      return;
    }

    if (!event?.changedPointers.length) {
      console.warn('Malformed tap event', event);
      return;
    }

    const changedPointer = event.changedPointers[0];

    const tapCoords = this.getAdjustedCoords(
      changedPointer.offsetX,
      changedPointer.offsetY
    );
    const durationMs = event.deltaTime;

    const command = new TapCommand(tapCoords.x, tapCoords.y, durationMs);
    this.remoteAccessClient
      .postCommand(
        this.sessionId,
        this.ccu.ccuId,
        command.toCommandString(),
        new Date().getTime()
      )
      .pipe(
        catchError((error) => {
          this.toastService.error('Failed to transmit tap gesture');
          return throwError(() => error);
        })
      )
      .subscribe();
  }

  /**
   * Wired up directly to the HammerJS (swipe) event emmitter. Determines the start/end coordinates of the movement
   * Posts a swipe command for each event.
   *
   * @param event
   * @returns
   */
  handleSwipe(event): void {
    if (this.isStaleSession) {
      return;
    }

    if (!event.changedPointers.length) {
      console.warn('Malformed swipe event', event);
      return;
    }

    const changedPointer = event.changedPointers[0];

    const x2 = changedPointer.offsetX;
    const y2 = changedPointer.offsetY;
    const x1 = x2 - event.deltaX;
    const y1 = y2 - event.deltaY;

    const durationMs = event.deltaTime;

    const startCoords = this.getAdjustedCoords(x1, y1);
    const endCoords = this.getAdjustedCoords(x2, y2);

    const command = new SwipeCommand(
      startCoords.x,
      startCoords.y,
      endCoords.x,
      endCoords.y,
      durationMs
    );
    this.remoteAccessClient
      .postCommand(
        this.sessionId,
        this.ccu.ccuId,
        command.toCommandString(),
        new Date().getTime()
      )
      .pipe(
        catchError((error) => {
          this.toastService.error('Failed to transmit swipe gesture');
          return throwError(() => error);
        })
      )
      .subscribe();
  }

  /**
   * Invoked by the buffered/debounced mouse wheel event subscription once a movement/gesture completes.
   * The aggregate X,Y delta is calculated, converted to a swipe command, and then posted to the Gestures API
   *
   * @param mouseWheelEvents Array of buffered events (assumed to be in order of ocurrence)
   * @returns
   */
  handleAggregatedMouseWheelEvents(mouseWheelEvents: WheelEvent[]) {
    if (!mouseWheelEvents?.length || this.isStaleSession) {
      return;
    }

    const initialEvent = mouseWheelEvents[0];
    const finalEvent = mouseWheelEvents[mouseWheelEvents.length - 1];

    let deltaX = mouseWheelEvents.reduce((delta, event) => {
      return delta + event.deltaX;
    }, 0);
    let deltaY = mouseWheelEvents.reduce((delta, event) => {
      return delta + event.deltaY;
    }, 0);

    const startCoords = this.getAdjustedCoords(
      initialEvent.offsetX,
      initialEvent.offsetY
    );
    const endCoords = {
      x: startCoords.x + deltaX,
      y: startCoords.y + deltaY,
    };

    let durationMs = Math.round(finalEvent.timeStamp - initialEvent.timeStamp);

    const command = new SwipeCommand(
      endCoords.x,
      endCoords.y,
      startCoords.x,
      startCoords.y,
      durationMs
    );
    this.remoteAccessClient
      .postCommand(
        this.sessionId,
        this.ccu.ccuId,
        command.toCommandString(),
        new Date().getTime()
      )
      .pipe(
        catchError((error) => {
          this.toastService.error('Failed to transmit mouse wheel gesture');
          return throwError(() => error);
        })
      )
      .subscribe();
  }

  /**
   * Determines the screen size ratio as currently displayed vs. the actual screen geometry of the
   * Device (CCU) and calculates the x,y coordinates relative to the tablet's dimensions. The coordinates
   * are also rounded, since the Remote Access Agent expects coordinate values to be sent as integers
   *
   * @param x
   * @param y
   * @returns {x, y} Coordinates adjusted to the actual screen size
   */
  getAdjustedCoords(x: number, y: number) {
    if (!this.ccu) {
      return { x, y };
    }

    const ratio =
      this.ccu.ccuMetadata.width /
      this.screenshotContainer.nativeElement.offsetWidth;

    return {
      x: Math.round(x * ratio),
      y: Math.round(y * ratio),
    };
  }

  sendNavCommand(appContext: AppContext, targetEntityId: string) {
    const navCommand = new NavCommand(appContext, targetEntityId);

    this.remoteAccessClient
      .postCommand(
        this.sessionId,
        this.ccu.ccuId,
        navCommand.toCommandString(),
        new Date().getTime()
      )
      .pipe(
        catchError((error) => {
          this.toastService.error('Failed to trigger automatic navigation');
          return throwError(() => error);
        })
      )
      .subscribe();
  }

  showStaleSessionWarningDialog() {
    this.dialog.open(StaleSessionWarningDialog, {
      data: {
        ccuName: this.ccu.ccuMetadata.name,
        staleThresholdSeconds: STALE_SESSION_THRESHOLD_SECONDS,
        endSession: () => { this.sessionEnd.emit({unexpected: false}) }
      },
    });
  }

  ngOnDestroy(): void {
    this.screenshotPollingSubscription?.unsubscribe();
    this.mouseWheelCaptureSubscription?.unsubscribe();
    this.keyPressCaptureSubscription?.unsubscribe();
    this.configChangeSubscription?.unsubscribe();
  }
}

@Component({
  template: `<h2 mat-dialog-title>CCU Unreachable</h2>
    <mat-dialog-content class="mat-typography">
      {{ data.ccuName }} has been unreachable for more than {{ data.staleThresholdSeconds }} seconds.
    </mat-dialog-content>
    <mat-dialog-actions style="justify-content: end; color: var(--lighter-grey)">
      <button mat-button mat-dialog-close color="primary">WAIT</button>
      |
      <button mat-button mat-dialog-close (click)="endSession()" style="color: var(--primary)">END SESSION</button>  
    </mat-dialog-actions>`,
})
export class StaleSessionWarningDialog {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

  endSession() {
    this.data.endSession();
  }
}
