import { BaseCommand } from './BaseCommand';

const SPECIAL_KEY_MAPPING = {
  ArrowLeft: 'arrowleft',
  ArrowRight: 'arrowright',
  Backspace: 'backspace',
  Delete: 'delete',
  End: 'end',
  Enter: 'enter',
  Home: 'home',
  Tab: 'tab',
};

export class SpecialKeyCommand implements BaseCommand {
  key: string;

  constructor(key: string) {
    this.key = key;
  }

  toCommandString = () => `specialkey,${this.key}`;

  public static getMappedSpecialKey(key: string): string | undefined {
    if (!key) {
      return undefined;
    }

    return SPECIAL_KEY_MAPPING[key];
  }
}
