import { Injectable } from '@angular/core';
import { DeviceStatus } from '../enums/DeviceStatus.enum';
import { Ccu } from '../models/Ccu.model';
import { SiloCcu } from '../models/SiloCcu.model';

@Injectable({
  providedIn: 'root',
})
export class UtilService {
  constructor() {}

  getUrlQueryParam(paramName: string): string {
    return new URL(window.location.href).searchParams.get(paramName);
  }

  mergeSiloAndRasDiscoveredCcu(
    siloCcu: SiloCcu,
    rasDiscoveredCcu: Ccu,
    siteId: string
  ): Ccu {
    if (!siloCcu && !rasDiscoveredCcu) {
      return undefined;
    }

    if (!rasDiscoveredCcu) {
      return {
        ccuId: siloCcu.ccuId,
        status: DeviceStatus.UNKNOWN,
        ccuMetadata: {
          name: siloCcu.name,
          siteId: siteId,
        },
      };
    }

    return {
      ...rasDiscoveredCcu,
      ccuMetadata: {
        ...rasDiscoveredCcu.ccuMetadata,
        name: siloCcu.name,
        siteId: siteId,
      },
    };
  }
}
