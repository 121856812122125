import { AppContext } from '../../enums/AppContext.enum';
import { BaseCommand } from './BaseCommand';

export class NavCommand implements BaseCommand {
  context: AppContext;
  entityId: string;

  constructor(context: AppContext, entityId: string) {
    this.context = context;
    this.entityId = entityId;
  }

  toCommandString = () => `nav,${this.context},${this.entityId}`;
}
