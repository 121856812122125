import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import {
  BrowserModule,
  HAMMER_GESTURE_CONFIG,
  HammerModule,
} from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from 'src/app/shared/shared.module';
import { AppComponent, ConfigDialog } from './app.component';
import { RemoteAccessPortalModule } from './remote-access-portal/remote-access-portal.module';
import { HammerJsConfiguration } from './shared/configuration/hammer-js-configuration';

@NgModule({
  declarations: [AppComponent, ConfigDialog],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    HammerModule,
    HttpClientModule,
    MatDialogModule,
    MatIconModule,
    MatMenuModule,
    SharedModule,
    RemoteAccessPortalModule,
  ],
  bootstrap: [AppComponent],
  providers: [
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: HammerJsConfiguration,
    },
  ],
})
export class AppModule {}
