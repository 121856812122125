import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AppContext } from '../enums/AppContext.enum';
import { RemoteAccessConfig } from '../models/RemoteAccessConfig.model';
import { SessionSettings } from '../models/SessionSettings.model';

// TODO: Move this to the remote configuration API if the need arises to modify it without a code change. 
//       Otherwise, keep it here and remove this comment
const CCU_STATUS_POLL_FREQUENCY_MS = 5000;

@Injectable({
  providedIn: 'root',
})
export class ConfigurationService {
  /**
   * The global configuration provided by the API once the virtual tablet component is loaded
   */
  remoteAccessConfig: RemoteAccessConfig;

  sessionId: string;
  debugModeEnabled: boolean;
  hostApp: string;
  appContext: AppContext;
  targetEntityId: string;

  ccuStatusPollFrequencyMs: number = CCU_STATUS_POLL_FREQUENCY_MS;

  configChangeSubject: Subject<RemoteAccessConfig> =
    new Subject<RemoteAccessConfig>();

  constructor() {}

  get(key: string) {
    return environment[key];
  }

  updateSessionSettings(sessionSettings: SessionSettings): void {
    this.remoteAccessConfig.quality = sessionSettings.qualityPercentage;
    this.remoteAccessConfig.refreshFrequency =
      sessionSettings.refreshIntervalMillis;
    this.remoteAccessConfig.uploadFrequency =
      sessionSettings.refreshIntervalMillis;

    this.configChangeSubject.next(this.remoteAccessConfig);
  }
}
